import * as React from 'react';

import {I18nextProvider} from 'react-i18next';

import {initI18n} from '@app/i18n';

const InitI18n: React.FC = ({children}) => {
	return <I18nextProvider i18n={initI18n('en')}>
		{children}
	</I18nextProvider>;
};

export default InitI18n as any;
