import * as React from 'react';
import { NavLink} from 'react-router-dom';

import {MenuItem} from '@common/react/objects/MenuItem';
import '@common/react/scss/components/menu.scss';

interface MenuItemProps extends Omit<MenuProps, 'items' | 'className'>{
	item: MenuItem & {blank?: boolean};
}

interface MenuProps {
	className?: string;
	items: MenuItem[];
	withChildren?: boolean;
	basePath?: string;
	pathKey?: string;
	defaultOpen?: boolean;
}

export const Item: React.FC<MenuItemProps> = ({defaultOpen, item, basePath, pathKey, withChildren}) => {
	const [open, setOpen] = React.useState<boolean>(defaultOpen || (typeof item.isOpen !== 'undefined' ? item.isOpen : false));

	const toggleMenu = () => setOpen(prev => !prev);

	const condition = withChildren && item.children && item.children.list && item.children.list.length > 0;

	const path = item[pathKey || 'path'];

	const {exact = false, isActive} = item;

	const className = `menu-component__item ${
		open && condition ? 'menu-component__item_open' : ''
	} ${
		condition ? 'menu-component__item_with-children' : ''
	} ${item.className || ''}`;

	return (
		<li className={className} >
			{path ? item.externalLink
					? <a className="menu-component__item-link" href={item.path} target={item.blank ? '_blank' : undefined}>
						{item.name}
					</a>
					: <NavLink
						exact={exact}
						to={basePath + path}
						className="menu-component__item-link"
						activeClassName="menu-component__item-link_active"
						isActive={isActive}
					>
						{item.name}
					</NavLink>
				: <span className="menu-component__item-link" onClick={toggleMenu} style={{ cursor: 'pointer' }}>{item.name}</span>
			}
			{condition &&
				<React.Fragment>
				<i className={`icons menu-component__item-toggle ${open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}`} onClick={toggleMenu}/>
				<div className={`menu-component__item-children-container ${open ? 'isOpen' : ''}`}>
				<ul className="menu-component__children">
							{item.children && item.children.list.map((child, index) =>
								<Item item={child} key={index} withChildren={withChildren} basePath={child.basePath || basePath || ''} pathKey={pathKey}/>
							)}
				</ul>
				</div>
				</React.Fragment>
			}
		</li>
	);
};

export const Menu: React.FC<MenuProps> = ({ className = '', items, withChildren = false, basePath, pathKey, defaultOpen }) => {
	const menuItems = items || [];

	return <ul className={`menu-component ${className}`}>
		{menuItems.map((item, index) =>
			<Item
				defaultOpen={defaultOpen}
				key={index}
				item={item}
				withChildren={item.children ? true : withChildren}
				basePath={item.basePath || basePath || ''}
				pathKey={pathKey}
			/>
		)}
	</ul>;
};