import i18n from 'i18next';

import translation from './translation';

export const initI18n = (initLang: string) => {
	i18n
		.init({
			resources: translation,
			fallbackLng: 'ru',
			lng: initLang,
			debug: false,
			interpolation: {
				escapeValue: false, // not needed for react as it escapes by default
			},
			react: {
				useSuspense: false
			}
		});

	return i18n;
};