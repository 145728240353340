import * as React from 'react';
import {useTranslation} from 'react-i18next';

import Select from 'antd/lib/select';

import {getLocalStorageValue, setLocalStorageValue} from '@common/react/utils/localStorage/localStorage';
import {Lang} from '@common/typescript/objects/Lang';

import {Menu} from '@app/components/UI/Header/Menu';

const Header: React.FC = () => {
	const {i18n, t} = useTranslation();
	const [active, setActive] = React.useState(false);
	const [open, setOpen] = React.useState(false);

	const changeLang = (lng: string) => {
		i18n.changeLanguage(lng.toLowerCase());
		setLocalStorageValue('language', lng.toLowerCase());
	};

	const mainMenu = [
		{
			path: '#aboutUs', name: t('header.aboutUs'), basePath: '/', fullPath: '', externalLink: true,
		},
		{
			path: '#products', name: t('header.products'), basePath: '/', fullPath: '', externalLink: true
		},
		{
			path: '#Partners', name: t('header.Partners'), basePath: '/', fullPath: '', externalLink: true
		},
		{
			path: '#contact-us', name: t('header.contacts'), basePath: '/', fullPath: '', externalLink: true
		}
	];

	React.useEffect(() => {
		const scroll = () => {
			setActive(window.scrollY > 100);
		};

		i18n.changeLanguage(getLocalStorageValue('language', 'en'));

		scroll();
		window.addEventListener('scroll', scroll);
		return () => window.removeEventListener('scroll', scroll);
	}, []);

	const handleMenuItemClick = (e) => {
		if (e.target?.classList.contains('menu-component__item-link')) {
			setOpen(false);
		}
	};

	return <header className={`site-header ${active ? 'site-header-active' : ''} ${open ? 'site-header__open' : ''}`}>
		<nav className="navbar navbar-default navbar-fixed-top">
			<div className="navbar__container">
				<div className="logo">
					<img alt="logo" src="/images/logo.svg" width="40" height="64"/>
					{t('home.companyNameLogo')}
				</div>
				<div className={`mobile-menu ${open ? 'mobile-menu__open' : ''}`} onClick={handleMenuItemClick}>
					<Menu items={mainMenu}/>
					<div className="show-mobile">
						<Select
							style={{width: 89}}
							getPopupContainer={node => node.closest('.site-header')}
							value={Lang[i18n.language === 'en' ? Lang.En : Lang.Ru]}
							onSelect={changeLang}
						>
							<Select.Option value={Lang[Lang.En]}>ENG</Select.Option>
							<Select.Option value={Lang[Lang.Ru]}>RUS</Select.Option>
						</Select>
					</div>
					<a href="tel:+77474165677" className="show-mobile phone">
						+7 747 416 5677
					</a>
				</div>
				<div className="hide-mobile">
					<Select
						style={{width: 89}}
						getPopupContainer={node => node.closest('.site-header')}
						value={Lang[i18n.language === 'en' ? Lang.En : Lang.Ru]}
						onSelect={changeLang}
					>
						<Select.Option value={Lang[Lang.En]}>ENG</Select.Option>
						<Select.Option value={Lang[Lang.Ru]}>RUS</Select.Option>
					</Select>
				</div>
				<a href="tel:+77474165677" className="phone hide-mobile">
					+7 747 416 5677
				</a>
				<button
					type="button"
					className={`navbar-burger ${open ? 'navbar-burger__open' : ''}`}
					onClick={() => setOpen(open => !open)}>
					<span/>
				</button>
			</div>
		</nav>
	</header>;
};

export default Header;
