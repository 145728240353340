import * as React from 'react';

import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

import '@app/scss/style.scss';

import Header from './UI/Header/Header';

gsap.registerPlugin(ScrollTrigger);

const Layout: React.FC = ({children}) => {
	return <div className="app-container">
		<Header/>
		<div className="content">{children}</div>
	</div>;
};

export default Layout;
