import React from 'react';

import '@app/scss/loading.scss';

const Loading: React.FC = () => {
	return (
		<div className="loading-component page-loading custom-loading">
			<span/>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="63"
				height="101"
				viewBox="0 0 63 101"
				fill="none"
			>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M35.0298 100.892C34.8151 100.679 34.5756 100.443 34.316 100.187L34.3144 100.186C33.5684 99.4512 32.6568 98.5535 31.6928 97.5532C29.5116 95.2898 26.8706 92.3126 24.2626 88.5132C24.2005 88.4617 24.1349 88.4087 24.0657 88.3544C23.2039 87.6783 21.8905 86.8795 19.9986 86.0801C16.3365 84.5327 10.627 83.0338 2.14229 82.3748C5.90502 91.9787 15.0631 102.16 35.0298 100.892ZM0.670924 77.614C8.34798 78.1949 15.633 79.4343 19.7772 80.8974C18.3272 77.9888 17.0007 74.7664 15.9007 71.2073C15.8792 71.1378 15.8569 71.0645 15.8335 70.9878C15.08 68.51 13.2232 62.4043 1.36383 61.6835C-0.0349889 67.0674 -0.506278 72.4772 0.657578 77.556C0.662006 77.5753 0.666455 77.5946 0.670924 77.614ZM2.81616 56.962C7.66973 57.3317 10.763 58.6318 13.4973 60.3544C12.5359 53.48 12.4353 45.6499 13.6448 36.7645C13.7426 36.0466 13.8443 35.3662 13.9531 34.6964C10.2414 40.9669 5.68961 48.8122 2.81616 56.962ZM18.61 35.7915C18.494 36.3623 18.3885 36.9656 18.2958 37.6599C17.3252 44.9268 17.4028 52.7402 17.9976 58.51C19.3259 55.4951 21.9895 50.5209 25.3648 47.6288C21.4105 43.0874 19.6498 39.4241 18.61 35.7915ZM28.9705 51.4133C23.157 55.8359 20.36 62.6262 20.3618 69.6184C21.6204 73.7341 23.1984 77.3423 24.9017 80.4912C25.0474 79.0499 25.2842 77.5145 25.6418 75.9354C26.9891 69.9866 30.1177 63.1348 36.8598 58.3375C36.6192 58.129 36.3729 57.922 36.1207 57.7164C33.3096 55.4249 30.9532 53.3394 28.9705 51.4133ZM40.1649 61.6994C34.943 65.679 31.3884 71.976 30.2518 76.9942C29.073 82.1993 29.436 86.6776 29.7251 87.9913C31.615 90.4982 33.4722 92.5625 35.086 94.2371C36.0132 95.1992 36.8244 95.9976 37.5448 96.7066C37.9186 97.0745 38.2684 97.4188 38.5968 97.7488C38.9665 98.1206 39.3206 98.4863 39.6223 98.8298C43.21 92.8567 51.4138 75.2624 40.1649 61.6994Z"
					fill="#1bb730"
				/>
			</svg>
		</div>
	);
};

export default Loading;
