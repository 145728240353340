/* tslint:disable */
export default {
	en: {
		translation: {
			header: {
				'aboutUs': 'About Us',
				'Partners': 'Partners',
				products: 'Products',
				contacts: 'Contacts'
			},
			home: {
				companyName: 'K.Petrotorg',
				companyNameLogo: 'K.Petrotorg',
				topBanner1: 'Import of products and products of popular brands from leading European companies',
				topBanner2: 'from leading European companies',
				topButton: 'Write to us',
				aboutCompany: 'About company',
				aboutCompanySubtitle1: 'Since 2015, K.Petrotorg has been operating in Kazakhstan',
				largeAssortment4: 'K.Petrotorg specializing in the import and sale of high-quality consumer goods from renowned European brands. ',
				since: 'c ',
				largeAssortment1: 'We deliver a diverse selection of top brands from Sweden, Denmark, and Armenia, including GN Tobacco, V2 Tobacco, Mad Monkeyz, Apran, and Selia.',
				largeAssortment2: 'As a leading player in both the wholesale and retail sectors, we continue to attract a growing number of customers who appreciate the quality and reputation of our brand portfolio. ',
				largeAssortment3: 'With their expertise, we are able to efficiently cater to the needs of our diverse clientele spread across multiple countries. Our in-depth knowledge of supply chain processes, combined with our unwavering focus on customer satisfaction, has helped us earn a reputation as a trusted and dependable partner for all our stakeholders.',
				ourTeam: 'our team',
				MoreThan15: 'Our team comprises of over 15 highly trained specialists in sales, purchasing, and logistics. ',
				ourGoals: 'OUR GOALS',
				muchMoreThanJust: 'At K.Petrotorg, we aim to be more than just a supplier of a wide range of goods.',
				ourGoalIsToEstablish: 'OUR GOAL IS TO ESTABLISH STRONG PARTNERSHIPS WITH BOTH OUR CLIENTS AND SUPPLIERS ACROSS THE GLOBE. WE TAKE PRIDE IN OFFERING PRODUCTS THAT MEET THE HIGHEST QUALITY STANDARDS, AS EVIDENCED BY THE APPROPRIATE CERTIFICATIONS.',
				chewingTobacco: 'Chewing Tobacco',
				whatIsThis: 'WHAT IS THIS',
				oneOfTheTypesOfSmokeless: 'Chewing tobacco is a type of smokeless, non-smoking tobacco product that is made from a combination of tobacco, water, and salt. During production, the tobacco is pasteurized to eliminate any unwanted substances or microbes and then packaged in small bags.',
				howToUse: 'HOW TO USE',
				aBagOfChewingTobaccoIsPlaced: 'A bag of chewing tobacco is placed under the upper lip for a period of 5-30 minutes. Nicotine is then absorbed into the body, and the bag can be periodically bitten to enhance the flavor.',
				advantages: 'ADVANTAGES',
				TheMainAdvantageOfChewingTobacco: 'The main advantages of chewing tobacco is that it is less harmful to health than smoking.',
				YesItContainsNicotineBut: 'Although it does contain nicotine, it is a smokeless tobacco product, which means that harmful tars are not produced during use, and there is no risk of combustion byproducts settling in the lungs. This makes it a viable alternative for individuals looking to consume nicotine in places where smoking is prohibited, such as airplanes, clubs, and public spaces.',
				hookahTobacco: 'Hookah Tobacco',
				HowIsHookahTobaccoDifferent: 'WHAT IS THE DIFFERENCE OF HOOKAH TOBACCO FROM REGULAR TOBACCO?',
				InTheManufactureOfCigarettes: 'While cigarette tobacco is simply collected, dried, and packed into cartridges, hookah tobacco is harvested by hand and cut into various shapes, avoiding fine shredding. The drying process for hookah tobacco is also more extensive, involving firing the tobacco multiple times.',
				'Муасель': 'MUASSEL',
				SuchADressingIncludesMolasses: 'This type of hookah tobacco includes molasses, syrup, honey, glycerin, and various fruit flavors. It is the most popular and commonly used type of hookah tobacco. The package contains prepared and crushed tobacco leaves soaked in a sweet and fragrant syrup.',
				'Томбак': 'TOMBAC',
				'Журак': 'JURAK',
				ThisIsPureClassicTobacco: 'This is a classic, pure form of tobacco that is still popular in many countries. Before smoking, the leaves must be prepared by soaking them in water and crushing them.',
				FinelyChoppedTobaccoLeaves: 'This type of hookah tobacco consists of finely chopped tobacco leaves mixed with molasses. It does not contain flavors, dyes, and molasses helps to maintain the structure of the tobacco while also adding sweetness. Sometimes, various spices, fruits, and berries may be added to give additional flavor notes.',
				ProductsFromArmenia: 'Products from Armenia',
				AparanWater: 'Aparan water',
				AparanIsOne: 'Aparan is one of the most renowned brands of bottled drinking water in Armenia. It is extracted from the springs located in the mountains of Aragatsotn province, which is known for its pure and fresh water. The water goes through a strict cleaning and quality control process to ensure its high quality and safety for consumers.',
				'These are high-quality olives': 'These are high-quality olives that are carefully selected, processed, and packaged in Armenia. They come in different varieties and are a great addition to salads, sandwiches, and other dishes.',
				'Ararat Kening Factory': 'Ararat Kening Factory and Green Grove are two well-known brands that offer high-quality canned vegetables and tomato paste. They use only the freshest and highest-quality ingredients, and their products are perfect for cooking a variety of dishes.',
				'CANNED VEGETABLES': 'CANNED VEGETABLES ARARAT KENING FACTORY AND GREEN GROVE TOMATO PASTE ',
				'At KPetrotorg, we are committed': 'At «K.Petrotorg», we are committed to providing our customers with the best products from Armenia and other countries, and we take pride in our ability to source and supply high-quality products that meet the needs and expectations of our clients.',
				WhatDoWeSupplyFromArmenia: 'WHAT WE SUPPLY FROM ARMENIA',
				OlivesSeliaAndAthena: 'Olives Selia and Athena',
				CannedVegetables: 'Canned vegetables Ararat Kening Factory and Green ',
				tomatoPaste: 'Grove tomato paste',
				OurPartners: 'OUR PARTNERS',
				company: 'Company ',
				gnTobacco: 'GN Tobacco Sweden AB ',
				GNTobaccoSwedenAB: 'is a reputable manufacturer of the most popular chewing tobacco in Scandinavia, known for producing one of the strongest varieties in the world - Oden\'s.',
				v2Tobacco: 'V2 Tobacco (Denmark)',
				v2TobaccoText1: ' is a well-known producer of top-quality chewing tobacco, founded on a deep appreciation of tobacco, a drive for excellence, and a commitment to delivering superior products.',
				v2TobaccoText2: 'V2 Tobacco offers a wide range of flavors and aromas sourced from fresh natural tobacco leaves, herbs, and spices.',
				v2TobaccoText4: 'Recently, the company joined the ranks of the large Swedish corporation Swedish Match, which further reinforces its reputation for quality and innovation.',
				v2TobaccoText3: 'All products are pasteurized and contain low levels of nitrosamines (TSNA), meeting the strict requirements of European and Scandinavian food and tobacco industry regulations.',
				spTobacco: 'Scandinavian Premium Tobacco (SPT, Sweden) ',
				'GN Tobacco website': 'GN Tobacco website',
				'V2 Tobacco website': 'V2 Tobacco website',
				'Mad Monkeyz website': 'Mad Monkeyz website',
				spTobaccoText1: 'was established in 2004 with the primary goal of producing top-quality Swedish tobacco products. The company has a modern manufacturing facility located on the picturesque island of Gotland, Sweden. Here, they blend, create, produce, and package unique tobacco products with distinct taste and aroma.',
				spTobaccoText2: 'In line with Swedish tobacco tradition, Scandinavian Premium Tobacco has innovated a new form and flavor. Their chewable pouches offer the ultimate pleasure and the highest quality. Meticulous tobacco cutting ensures maximum flavor from the finest, carefully selected tobacco leaves.',
				madMonkeyz: 'Mad Monkeyz Company (Armenia) ',
				madMonkeyzText: 'has been crafting premium quality hookah tobacco for nearly a decade. The company\'s unwavering focus on quality and attention to detail has enabled them to source and procure the finest tobaccos for their products. With a special expertise in dark tobaccos, Mad Monkeyz sets itself apart from competitors by expertly selecting, processing, and blending them with unique flavors. Through years of dedication and experimentation, Mad Monkeyz has perfected the art of creating exceptional hookah tobacco blends.',
				TheCompanyHasStateOfTheArt: 'The company operates a state-of-the-art plant located in Jarfaala, Sweden, equipped with modern machinery and designed for high production capacity. The plant carries out all stages of production using fully automated processes under strict quality control measures.',
				TheTobaccoUsedByGNTobaccoSwedenAB: 'The tobacco used by GN Tobacco for production comes from the fertile red soil of Viñales, Cuba, where traditional hand-picking techniques are used for harvesting and selection. The tobacco undergoes fermentation twice, which is necessary for biochemical changes in tobacco leaf tissues. The resulting aromas and flavors are distinct and palpable during consumption.\n\n',
				AfterAllThePreparatoryMeasures: 'After fermentation, the tobacco is cut into small pieces, moistened, mixed, and then stored in a refrigerated space for flavor ripening. Finally, the tobacco is sifted, enriched with oxygen, and then packaged. \n\n GN Tobacco offers a variety of more than 90 flavor variations of chewing tobacco products to its customers.',
				ParticipationInExhibitions: 'Participation in exhibitions',
				Astana: 'ASTANA',
				Almaty: 'ALMATY',
				Moscow: 'MOSCOW',
				TheRepublicofKazakhstan: 'Republic of Kazakhstan, Almaty, st. Suyunbaya, d. 2, TC "Merey"',
				PleaseDoNotHesitateToContact: 'Feel free to contact us if you require any further information',

			},
			forms: {
				"Заявка": 'Your inquiry has been submitted...',
				login: 'Login',
				email: 'Email',
				password: 'Password',
				repeatPassword: 'Repeat password',
				passwordMessage: 'Password successfully changed',
				setupPassword: 'Setup password',
				enterPassword: 'Enter new password',
				firstName: 'First Name',
				lastName: 'Last Name',
				phone: 'Phone',
				name: 'Name*',
				message: 'Message',
				forgetPassword: 'Forget password?',
				register: 'Register',
				submit: 'Submit',
				captcha: 'Enter the symbols',
				emailSent: 'An email has been sent to you with further instructions.',
				recoverText: 'Enter the email you provided during registration and we will send you a password reset email',
				recover: 'Recover',
				recoverPassword: 'Recover password',
				'Cosmetic or Dermatology': 'Cosmetic or Dermatology',
				'Procedure of interest': 'Procedure of interest',
				'Location Preference': 'Location Preference',
				'Additional Comments': 'Additional Comments',
				'Age': 'Age',
				'Gender': 'Gender',
				'Height': 'Height',
				'Ethnicity': 'Ethnicity',
				'Weight': 'Weight',
				'Male': 'Male',
				'Female': 'Female',
				'Other': 'Other',
				'Filters': 'Filters',
				'Select doctor': 'Select doctor',
				requestSuccess: 'Your request has been sent successfully',
				'Time': 'Time',
				'Date': 'Date',
				autocomplete: 'Start typing for search...',
				'Left Implant': 'Left Implant',
				'Right Implant': 'Right Implant',
				'Pre-op Size': 'Pre-op Size',
				'Post-op Size': 'Post-op Size',
				'Reset All': 'Reset All',
				search: 'Search...',
				notFound: 'Not found',
				'Find More': 'Find More'
			},
			errors: {
				'Required field!': 'Required field',
				'Required field': 'Required field',
				'Invalid email': 'Invalid email',
				'Invalid phone number': 'Invalid phone number',
				'Must be less than character': 'Must be less than {{max}} character'
			},
			site: {
				loading: 'Loading',
			},
			seo: {
				homePage: {
					title: 'Houston Dermatology, Plastic Surgery & Med Spa',
					link: '/'
				}
			}
		}
	},
	ru: {
		translation: {
			header: {
				'aboutUs': 'О нас',
				'Partners': 'Партнеры',
				products: 'Продукция',
				contacts: 'Контакты'
			},
			home: {
				companyName: '«К.Петроторг»',
				companyNameLogo: 'К.Петроторг',
				topBanner1: 'Импорт продуктов и изделий популярных брендов от ведущих европейских компаний',
				topBanner2: 'брендов от ведущих европейских компаний',
				topButton: 'Напишите нам',
				aboutCompany: 'О компании',
				aboutCompanySubtitle1: 'Компания «К.Петроторг» работает в Казахстане с 2015 года. ',
				largeAssortment4: 'Компания занимается импортом и продажей  товаров народного потребления известных торговых марок из Европы.',
				since: 'c ',
				largeAssortment1: 'Мы предлагаем большой ассортимент продуктов ведущих брендов Швеции, Дании, Армении, таких как: GN Tobacco, V2 Tobacco, Mad Monkeyz, Apran, Selia',
				largeAssortment2: 'Также мы являемся важным оператором в оптовом и розничном бизнесе, где все большее число покупателей открывают для себя наши хорошо известные бренды.',
				largeAssortment3: '«К.Петроторг» работает с сотнями клиентов в разных странах. Полное знание процессов цепочки поставок, постоянное внимание к клиентам делают нашу компанию надежным партнером для всех заинтересованных сторон.',
				ourTeam: 'Наша команда',
				MoreThan15: 'Более 15 квалифицированных специалистов по продажам, закупкам и логистике',
				ourGoals: 'Наши цели',
				muchMoreThanJust: '«К.Петроторг» намного больше, чем просто поставщик различных товаров',
				ourGoalIsToEstablish: 'Наша цель - устанавливать партнерские отношения с нашими клиентами, а также с поставщиками по всему миру. Все товары отвечают стандартам качества, что подтверждено соответствующими сертификатами.',
				chewingTobacco: 'Жевательный табак',
				whatIsThis: 'Что это',
				oneOfTheTypesOfSmokeless: 'Один из видов бездымного, некурительного табака, состоит из табака, воды и соли. Чтобы уничтожить микробы и другие нежелательные вещества, табачную массу подвергают пастеризации при производстве после чего фасуют в пакетики.',
				howToUse: 'Как использовать',
				aBagOfChewingTobaccoIsPlaced: 'Пакетик с жевательным табаком закладывают под верхнюю губу на 5-30 минут, в течение которых никотин поступает в организм человека. Пакетик периодически следует надкусывать для усиления вкуса',
				advantages: 'Преимущества',
				TheMainAdvantageOfChewingTobacco: 'Основное преимущество употребления жевательного табака состоит в том, что он наносит существенно меньший вред здоровью, в отличие от курения.',
				YesItContainsNicotineBut: 'Да, он содержит никотин, но так как это бездымный табак, то при его употреблении не образуются вредные смолы, а в легких не оседают продукты сгорания сигаретной бумаги. Жевательный табак дает возможность употреблять никотин в самолётах, клубах и общественных местах — там, где курение запрещено.',
				hookahTobacco: 'Кальянный табак',
				HowIsHookahTobaccoDifferent: 'Чем отличается кальянный табак от обычного?',
				InTheManufactureOfCigarettes: 'При изготовлении сигарет, табачный лист просто собирается, высушивается и забивается в гильзы. Кальянный табак собирается вручную и нарезается разными формами, избегая мелкой шинковки. Сушка проводится несколько раз с использованием обжига.',
				SuchADressingIncludesMolasses: 'Такая заправка включает в себя патоку, сироп, мед, глицерин, ароматизаторы, фрукты. Считается самым популярным и ходовым видом кальянной заправки. В упаковке подготовленные и измельченные табачные листья, пропитанные сладким и ароматным сиропом.',
				'Муасель': 'Муасель',
				'Томбак': 'Томбак',
				'Журак': 'Журак',
				ThisIsPureClassicTobacco: 'Это чистый классический табак. Во многих странах кальян с томбаком еще продолжает пользоваться популярностью. Перед курением его необходимо подготовить, вымочить листья в воде и дополнительно измельчить.',
				FinelyChoppedTobaccoLeaves: 'Мелко измельченные листочки табака, с патокой. В своем составе не имеет ароматизаторов, красителей, а патока позволяет держать структуру и придает сладости такому табаку. Иногда для придания дополнительных вкусовых нот могут включать в себе различные специи, фрукты и ягоды.',
				ProductsFromArmenia: 'Продукты из Армении',
				AparanWater: 'Вода Aparan',
				AparanIsOne: '"Aparan" - это один из наиболее известных брендов бутилированной питьевой воды в Армении. Вода Aparan добывается из источников в горах Арагацотнской долины, расположенной в северной части страны.\n' +
					'Эта вода считается одной из самых чистых и свежих в Армении. Она проходит строгий процесс очистки и контроля качества, чтобы обеспечить потребителям высокое качество и безопасность.',
				WhatDoWeSupplyFromArmenia: 'Что мы поставляем из армении',
				OlivesSeliaAndAthena: 'Оливки Selia и Athena',
				CannedVegetables: 'Консервированные овощи Арарат Кенинг Фектори и Грин Грув',
				tomatoPaste: 'Томатная паста',
				OurPartners: 'Наши партнёры',
				company: 'Компания ',
				gnTobacco: 'GN Tobacco Sweden AB (Швеция)',
				v2Tobacco: 'V2 Tobacco (Дания)',
				v2TobaccoText1: ' - знаменитый производитель жевательного табака высокого качества. Философия компании V2 Tobacco и ведущие ценности строятся на любви к табаку, стремлению к превосходству и качественных преимуществах.',
				v2TobaccoText2: 'Продукция от V2 Tobacco представлена в полном разнообразии вкусов и ароматов, полученных из свежих натуральных табачных листьев, трав и специй. Вся продукция V2 Tobacco пастеризована и содержит низкий уровень нитрозаминов (TSNA).',
				v2TobaccoText3: 'В дополнение к собственным строгим требованиям, вся продукция V2 Tobacco соблюдает все Европейские и Скандинавские стандарты регулирования пищевой и табачной промышленности.',
				v2TobaccoText4: 'Не так давно компания стала частью большого шведского концерна Swedish Match.',
				spTobacco: 'Scandinavian Premium Tobacco (SPT, Швеция)',
				'GN Tobacco website': 'сайт GN Tobacco',
				'V2 Tobacco website': 'сайт V2 Tobacco',
				'Mad Monkeyz website': 'сайт Mad Monkeyz',
				spTobaccoText1: ' - основанная в 2004 году с единственной целью создавать шведские табачные изделия самого высокого качества. Компания имеет современные производственные мощности, расположенные на прекрасном острове Готланд, Швеция. Здесь создается  шведский жевательный табак – смешивание, создание, производство и упаковка уникальных табачных изделий, их вкуса и запаха.',
				spTobaccoText2: 'В соответствии с шведской табачной традицией компания “Скандинавский Премиальный Табак” изобрела новую форму и вкус. Жевательные пакетики обеспечивают максимальное удовольствие и высочайшее качество. Правильная нарезка табака даёт максимальный вкус тончайшего, вручную собранного табака.',
				madMonkeyz: 'Mad Monkeyz  (Армения)',
				madMonkeyzText: ' – компания занимается производством кальянного табака уже почти 10 лет. Внимание к деталям на протяжении всех этих лет дало возможность компании выбирать и закупать лучшие табаки для производства. Особое понимание темных табаков, полученное за время работы, отличает компанию от любого другого конкурента. Как их выбирать, как их обрабатывать, как сочетать с ароматами – все свои достижения Mad Monkeyz воплотил в замечательных табаках для кальяна.',
				TheCompanyHasStateOfTheArt: 'Завод компании расположен в шведском городе Ярфаала (Jarfalla, Швеция) недалеко от столицы Стокгольма. Завод  оснащен всем современным оборудованием и имеет высокие производственные мощности. Здесь жевательный табак проходит все этапы производства . Все процессы работы с табаком полностью автоматизированы и проходят полный контроль. ',
				GNTobaccoSwedenAB: ' - производитель самого популярного жевательного табака в Скандинавии – Oden’s. Жевательный табак Oden’s - один из самых крепких в мире. Ассортимент завода насчитывает более 90 вкусовых вариаций продукции.',
				TheTobaccoUsedByGNTobaccoSwedenAB: 'Табак, используемый компанией GN Tobacco Sweden AB для производства жевательного табака, произрастает на плодородной красной почве Виньялес, Куба, где листья табака собираются и отбираются традиционным ручным способом.\n',
				AfterAllThePreparatoryMeasures: 'После всех подготовительных мероприятий табак попадает прямо на завод. Для производства используется только специально приготовленный табак. Листья сначала проходят через ферментацию, как правило, дважды. Эти процессы необходимы для биохимических изменений в тканях листьев табака. В момент жевания табачных пакетиков вы ощущаете результаты этих процессов в виде специфических и вполне ощутимых ароматов и привкусов.\n' +
					'Затем табак разрезают на мелкие части, увлажняют, смешивают и отправляют в специальное охлажденное хранилище для дозревания вкуса. Перед упаковкой табак просеивается и обогащается кислородом.',
				ParticipationInExhibitions: 'Участие в выставках',
				Astana: 'Астана',
				Almaty: 'Алматы',
				Moscow: 'Москва',
				TheRepublicofKazakhstan: 'Республика Казахстан, г. Алматы, ул. Суюнбая, д. 2, ТК «Мерей»',
				PleaseDoNotHesitateToContact: 'Пожалуйста, не стесняйтесь обращаться к нам за дополнительной информацией!',

			},
			forms: {
				"Заявка": 'Заявка оправлена...',
				login: 'Login',
				email: 'Email',
				password: 'Password',
				repeatPassword: 'Repeat password',
				passwordMessage: 'Password successfully changed',
				setupPassword: 'Setup password',
				enterPassword: 'Enter new password',
				firstName: 'First Name',
				lastName: 'Last Name',
				name: 'Имя*',
				message: 'Сообщение',
				phone: 'Phone',
				forgetPassword: 'Forget password?',
				register: 'Register',
				submit: 'Oтправить',
				captcha: 'Enter the symbols',
				emailSent: 'An email has been sent to you with further instructions.',
				recoverText: 'Enter the email you provided during registration and we will send you a password reset email',
				recover: 'Recover',
				recoverPassword: 'Recover password',
				'Cosmetic or Dermatology': 'Cosmetic or Dermatology',
				'Procedure of interest': 'Procedure of interest',
				'Location Preference': 'Location Preference',
				'Additional Comments': 'Additional Comments',
				'Age': 'Age',
				'Gender': 'Gender',
				'Height': 'Height',
				'Ethnicity': 'Ethnicity',
				'Weight': 'Weight',
				'Male': 'Male',
				'Female': 'Female',
				'Other': 'Other',
				'Filters': 'Filters',
				'Select doctor': 'Select doctor',
				requestSuccess: 'Your request has been sent successfully',
				'Time': 'Time',
				'Date': 'Date',
				autocomplete: 'Start typing for search...',
				'Left Implant': 'Left Implant',
				'Right Implant': 'Right Implant',
				'Pre-op Size': 'Pre-op Size',
				'Post-op Size': 'Post-op Size',
				'Reset All': 'Reset All',
				search: 'Search...',
				notFound: 'Not found',
				'Find More': 'Find More'
			},
			errors: {
				'Required field!': 'Обязательное поле',
				'Required field': 'Обязательное поле',
				'Invalid email': 'Неверный адрес электронной почты',
				'Invalid phone number': 'Неверный номер телефона',
				'Must be less than character': 'Must be less than {{max}} character'
			},
			site: {
				loading: 'Loading',
			},
			seo: {
				homePage: {
					title: 'Houston Dermatology, Plastic Surgery & Med Spa',
					link: '/'
				}
			}
		}
	}
};