import * as React from 'react';
import {Route, Switch} from 'react-router-dom';

import loadable from '@loadable/component';

import {loadableDelay} from '@common/react/loadable/loadableSettings';

import InitI18n from '@app/components/InitLang';
import Loading from '@app/components/UI/Loading';

import Layout from './components/Layout';

const Home = loadable(() => 
	loadableDelay(import(/* webpackChunkName: "HomePage" */ './components/Pages/Home/Home')), {fallback: <Loading/>});

export const routes = <InitI18n>
	<Layout>
		<Switch>
			<Route exact path="/" component={Home} />
		</Switch>
	</Layout>
</InitI18n>;
